.aboutpage {
  background-image: url('../images/image1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding: 2rem;
  color: white;
}
.hero {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  padding-left: 50px; 
}
.aboutcard-container {
  display: flex;
  justify-content: space-around;
  margin: 2rem auto;
  max-width: 1400px;
}
.aboutcard {
  background-color: white;
  width: 25%;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  max-height: 400px; 
}
.card:hover {
  transform: scale(1.05);
}
.card-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 60px;
  margin-bottom: 1rem;
}
.card-content h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: orange;
}
.card-content p {
  font-size: 1rem;
  color: black;
  line-height: 1.5;
}
.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  text-align: left;
}
.about-us-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}
.about-us-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}
.about-us-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.about-us-text {
  width: 60%;
  padding-right: 20px;
}
.about-us-text p {
  font-size: 24px; 
  line-height: 2;
}
.about-us-image-container {
  width: 40%;
  text-align: right;
}
.about-us-image {
  max-width: 90%;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }
  .card {
    width: 80%;
    margin-bottom: 1rem;
  }
  .about-us-content {
    flex-direction: column;
  }
  .about-us-text, .about-us-image-container {
    width: 100%;
    text-align: center;
  }
  .about-us-image {
    margin-top: 20px;
  }
}
.expert-section {
  padding: 2rem;
  text-align: center;
  overflow: hidden;
}
.expert-section h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
.expert-card-container {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  animation: scroll 12s linear infinite; 
}
.expert-card {
  background-color: white;
  min-width: 20%;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}
.expert-card:hover {
  transform: scale(1.05);
}
.expert-image {
  width: 100%;
  height: 450px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
.expert-name h3 {
  font-size: 1.2rem;
  color: orange;
  margin-top: 1rem;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (max-width: 768px) {
  .expert-card {
    min-width: 45%;
  }
}
@media (max-width: 480px) {
  .expert-card {
    min-width: 90%;
  }
}
.expert-bio p {
  font-size: 1rem; 
  color: black;
  line-height: 1.5;
  margin-top: 0.5rem;
}
.contact-section {
  position: relative;
  margin: 20px 0;
}
.contact-image {
  background-size: cover;
  background-position: center;
  height: 500px;
  position: relative;
}
.contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}
.contact-overlay h1 {
  margin: 0 0 20px;
}
.contact-button {
  background-color: #007bff;
  color: white;
  padding: 12px 25px; 
  text-decoration: none;
  border-radius: 5px;
  margin-left: 50px;
  font-weight: bold;
}
