body {
    font-family: "Poppins", sans-serif; 
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }

  .eventOverview {
    position: relative;
    width: 92%;
    height: 600px;
    background-color: #97234c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    margin-top: 0;
    margin: 0;
  }
  
  .eventOverlayText {
    color: #fff;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
  }
  
  .eventOverlayText h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .eventDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .eventDate,
  .eventLocation {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .icon {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .buttonGroup {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
  
  .eventButton {
    text-decoration: none;
    padding: 12px 25px;
    font-size: 1.2rem;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .eventButton:hover {
    background-color: #45a049;
    transform: translateY(-5px);
  }
  
  .eventButton:active {
    transform: translateY(2px);
  }
  
  .countdownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 70px;
    padding: 2 0px;
    color: white;
  }
  
  .countdownTimer {
    display: flex;
    justify-content: center;
    gap: 10px;
    animation: fadeIn 1s ease;
  }
  
  .timeBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .timeBox span:first-child {
    font-size: 2.8rem;
    font-weight: bold;
    color: #fff;
    animation: fadeNumber 1s infinite alternate;
  }
  
  .timeBox span:last-child {
    font-size: 1.1rem;
    margin-top: 10px;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes fadeNumber {
    0% {
      color: rgba(255, 255, 255, 0.8);
    }
    100% {
      color: #fff;
    }
  }
  
  @media (max-width: 1200px) {
    .eventOverview {
      flex-direction: column;
      padding: 0 50px;
      height: auto;
    }
  
    .eventOverlayText h1 {
      font-size: 2.2rem;
    }
  
    .eventOverlayText p {
      font-size: 1.1rem;
    }
  
    .eventDate,
    .eventLocation {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 1024px) {
    .eventOverview {
      padding: 0 30px;
    }
  
    .eventOverlayText h1 {
      font-size: 2rem;
    }
  
    .eventOverlayText p {
      font-size: 1rem;
    }
  
    .eventDetails {
      gap: 5px;
    }
  
    .buttonGroup {
      flex-direction: column;
      gap: 10px;
    }
  
    .eventButton {
      padding: 10px 20px;
    }
  
    .countdownTimer {
      gap: 15px;
    }
  
    .timeBox {
      padding: 40px;
    }
  
    .timeBox span:first-child {
      font-size: 2.4rem;
    }
  
    .timeBox span:last-child {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .eventOverview {
      padding: 0 20px;
    }
  
    .eventOverlayText h1 {
      font-size: 1.8rem;
    }
  
    .eventOverlayText p {
      font-size: 0.9rem;
    }
  
    .eventDetails {
      gap: 5px;
    }
  
    .countdownTimer {
      gap: 10px;
      margin-left: 70px;
      flex-direction: row;
    }
  
    .timeBox {
      padding: 30px;
    }
  
    .timeBox span:first-child {
      font-size: 2rem;
    }
  
    .timeBox span:last-child {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .eventOverview {
      padding: 0 10px;
    }
  
    .eventOverlayText h1 {
      font-size: 1.5rem;
    }
  
    .eventOverlayText p {
      font-size: 0.8rem;
    }
  
    .eventDetails {
      gap: 5px;
    }
  
    .eventButton {
      padding: 8px 15px;
      font-size: 1rem;
    }
  
    .countdownContainer {
      margin-right: 0;
    }
  
    .timeBox {
      padding: 10px;
    }
  
    .timeBox span:first-child {
      font-size: 1.8rem;
    }
  
    .timeBox span:last-child {
      font-size: 0.8rem;
    }
  }
  
    .newconference-overview {
      background-color: none;
      padding: 50px;
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333;
    }
    
    .newimage-overview {
      margin-top: 10px;
    }
    
    .newimage-overview img {
      width: 100%;
      max-width: 1038px;
      height: 500px;
      border-radius: 10px;
    }
  
  .confnewtext-overview {
    margin-top: 30px;
    max-width: 900px;
    margin: 0 auto;
    padding: 30px; 
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif; 
    border-radius: 10px; 
  }
  
  .confnewtext-overview h2 {
    color: #003366;
    font-size: 34px; 
    font-weight: bold;
    margin-bottom: 24px;
    letter-spacing: 1px; 
  }
  
  .confnewtext-overview p {
    font-size: 20px; 
    line-height: 1.8; 
    color: #333; 
    margin-bottom: 18px; 
    text-align: justify; 
  }
  
  .confnewtext-overview strong {
    font-weight: 700; 
    color: #222; 
  }
  
  .confnewtext-overview br {
    line-height: 1.8; 
  }
  
  
  @media (max-width: 1200px) {
    .confnewtext-overview {
      padding: 25px; 
    }
    .confnewtext-overview h2 {
      font-size: 30px; 
    }
    .confnewtext-overview p {
      font-size: 18px; 
    }
  }
  @media (max-width: 992px) {
    .confnewtext-overview {
      padding: 20px; 
    }
    .confnewtext-overview h2 {
      font-size: 28px; 
    }
    .confnewtext-overview p {
      font-size: 17px; 
    }
  }
  @media (max-width: 768px) {
    .confnewtext-overview {
      padding: 15px; 
    }
    .confnewtext-overview h2 {
      font-size: 26px; 
    }
    .confnewtext-overview p {
      font-size: 16px; 
      line-height: 1.6; 
    }
  }





  
  .conference-Topics {
      max-width: 1200px;
      margin: 0 auto;
      padding: 40px 20px;
      font-family: 'Helvetica Neue', Arial, sans-serif;
    }
    
    .conference-Topics h1 {
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #333;
      margin-bottom: 40px;
    }
    
    .newTopics-card-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
    }
    
    .newTopics-card {
      background-color: #f9f9f9;
      flex: 1 1 calc(30% - 80px); 
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .newTopics-card:hover {
      transform: translateY(-10px); 
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    
    .newTopics-card h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #333;
      text-align: left;
    }
    
    .newTopics-card ul {
      list-style-type: none;
      padding-left: 0;
    }
    
    .newTopics-card ul li {
      font-size: 16px;
      color: #555;
      margin-bottom: 10px;
    }
    
    @media (max-width: 1024px) {
      .newTopics-card {
        flex: 1 1 calc(33.33% - 20px); 
      }
    }
    
    @media (max-width: 768px) {
      .newTopics-card {
        flex: 1 1 calc(50% - 20px); 
      }
    }
    
    @media (max-width: 480px) {
      .newTopics-card {
        flex: 1 1 100%; 
      }
    } 
  
    .new-venue {
      padding: 40px;
      text-align: center;
      margin-top: 1px;
    }
    
    .new-venue h2 {
      font-size: 52px;
      color: #2d5e7e;
      margin-bottom: 10px;
      margin-top: 20px;
      position: relative;
    }
    
    .new-venue h2::after {
      content: "";
      width: 60px;
      height: 3px;
      background: #f4f4f4;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .new-venue p {
      font-size: 24px;
      color: #333;
    }






    .new-agenda-section {
      margin-top: 1px;
      text-align: center;
      padding: 40px;
      background-color: #f4f4f4;
      color: #f1f1f1;
    }
    
    .new-agenda-section h2 {
      font-size: 36px;
      margin-bottom: 30px;
      text-transform: uppercase;
      color: #61dafb;
    }
    
    .conferenceday-buttons {
      margin-bottom: 30px;
    }
    
    .conferenceday-buttons button {
      background-color: #61dafb;
      border: none;
      color: #fff;
      padding: 12px 24px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      margin: 5px;
      transition: background-color 0.3s, transform 0.3s;
    }
    
    .conferenceday-buttons button:hover {
      background-color: #21a1f1;
      transform: scale(1.05);
    }
    
    .conferenceday-buttons .active {
      background-color: #333;
      color: #61dafb;
    }
    
    .conference-section-table {
      width: 90%;
      margin: 0 auto;
      border-collapse: collapse;
      background-color: #f1f1f1;
      border-radius: 8px;
      overflow: hidden;
    }
    
    .conference-section-table th,
    .conference-section-table td {
      padding: 15px;
      text-align: left;
      font-size: 18px;
    }
    
    .conference-section-table th {
      background-color: #61dafb;
      color: white;
      font-size: 20px;
    }
    
    .conference-section-table td {
      color: #333;
    }
    
    .conference-section-table tr:nth-child(even) {
      background-color: #f7f7f7;
    }
    
    .conference-section-table tr:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
    
    .conference-section-table tr:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    
    @media (max-width: 768px) {
      .new-agenda-section {
        padding: 20px;
      }
    
      .conference-section-table {
        width: 100%;
      }
    
      .conferenceday-buttons button {
        font-size: 14px;
        padding: 10px 18px;
      }
    
      .conference-section-table th,
      .conference-section-table td {
        font-size: 16px;
        padding: 10px;
      }
    }



    .registration-carai {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 100vh;
      padding: 50px 20px;
      background: linear-gradient(to bottom, #044a6b, #0a3c5d);
  }
  
  .intro-carai h1 {
      font-size: 2.8em;
      font-weight: bold;
      margin-bottom: 20px;
      color: #FFFFFF;
      animation: fadeInDown 1s ease-in-out;
  }
  
  .intro-carai p {
      font-size: 1.2em;
      margin: 10px 0;
      color: #E0E0E0;
      animation: fadeInUp 1s ease-in-out;
  }
  
  .registration-card-carai {
      display: grid;
      grid-template-columns: repeat(3, 1fr); 
      gap: 70px; 
      max-width: 1200px; 
      margin: 0 auto;
      padding: 0 20px; 
      justify-content: center; 
      animation: fadeIn 1.5s ease-in-out;
  }
  
  .registration-caraicard {
      background-color: #F7F9FC;
      color: #0D3B66;
      border-radius: 10px;
      padding: 25px;
      width: 100%;
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
      text-align: center;
      transform: scale(1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
  }
  
  .registration-caraicard:hover {
      transform: scale(1.08);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  .registration-caraicard h3 {
      font-size: 1.8em;
      margin-bottom: 15px;
      color: #0056b3;
      text-transform: uppercase;
      letter-spacing: 1.5px;
  }
  
  .registration-caraicard ul {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;
  }
  
  .registration-caraicard ul li {
      font-size: 1em;
      margin: 8px 0;
      color: #3C4858;
  }
  
  .registration-caraicard p {
      font-size: 1.6em;
      color: #FF5A5F;
      font-weight: bold;
      margin-bottom: 20px;
  }
  
  .registration-caraicard button {
      background-color: #007BFF;
      color: #FFFFFF;
      border: none;
      padding: 12px 25px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease;
  }
  
  .registration-caraicard button:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
  }
  
  @keyframes fadeIn {
      from {
          opacity: 0;
          transform: translateY(20px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @keyframes fadeInDown {
      from {
          opacity: 0;
          transform: translateY(-30px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @keyframes fadeInUp {
      from {
          opacity: 0;
          transform: translateY(30px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @media (max-width: 1024px) {
      .registration-card-carai {
          grid-template-columns: repeat(2, 1fr); 
          gap: 60px; 
          padding: 0 30px; 
      }
  }
  
  @media (max-width: 768px) {
      .registration-card-carai {
          grid-template-columns: 1fr; 
          gap: 40px; 
          padding: 0 15px; 
      }
  }
  
  @media (max-width: 480px) {
      .registration-card-carai {
          grid-template-columns: 1fr; 
          gap: 30px; 
          padding: 0 10px; 
      }
  }
  