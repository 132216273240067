body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}
.troverlay-container {
  position: relative;
  width: 90%;
  height: 600px;
  margin-top: 0;
  background-color: #2d5e7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  margin: 0;

}
.troverlay-text {
  color: #fff;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
}

.troverlay-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.troverlay-text p {
  font-size: 1.3rem;
  margin-bottom: 30px;
}

.trevent-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.trevent-date,
.trevent-location {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 1.5rem;
  color: #fff;
}

.trbutton-group {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.trevent-button {
  text-decoration: none;
  padding: 12px 25px;
  font-size: 1.2rem;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.trevent-button:hover {
  background-color: #45a049;
  transform: translateY(-5px);
}

.trevent-button:active {
  transform: translateY(2px);
}

.trcountdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
}

.trcountdown-timer {
  display: flex;
  justify-content: center;
  gap: 20px;
  animation: fadeIn 1s ease;
}

.trtime-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  animation: bounce 1.5s infinite ease-in-out;
}

.trtime-box span:first-child {
  font-size: 2.8rem;
  font-weight: bold;
  color: #fff;
  animation: fadeNumber 1s infinite alternate;
}

.trtime-box span:last-child {
  font-size: 1.1rem;
  margin-top: 10px;
}

/* Media Queries for responsiveness */

@media (max-width: 1200px) {
  .troverlay-container {
    flex-direction: column;
    padding: 0 50px;
    height: auto;
  }

  .trcountdown-container {
    margin-right: 0;
    margin-top: 40px;
  }

  .troverlay-text h1 {
    font-size: 2rem;
  }

  .troverlay-text p {
    font-size: 1.1rem;
  }

  .trevent-button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .trtime-box {
    padding: 30px;
  }

  .trtime-box span:first-child {
    font-size: 2.4rem;
  }
}

@media (max-width: 768px) {
  .troverlay-container {
    padding: 0 30px;
  }

  .troverlay-text h1 {
    font-size: 1.8rem;
  }

  .troverlay-text p {
    font-size: 1rem;
  }

  .trevent-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .trtime-box {
    padding: 20px;
  }

  .trtime-box span:first-child {
    font-size: 2rem;
  }

  .trcountdown-container {
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .troverlay-container {
    padding: 0 20px;
  }

  .troverlay-text h1 {
    font-size: 1.5rem;
  }

  .troverlay-text p {
    font-size: 0.9rem;
  }

  .trevent-button {
    padding: 6px 14px;
    font-size: 0.8rem;
  }

  .trtime-box {
    padding: 15px;
  }

  .trtime-box span:first-child {
    font-size: 1.6rem;
  }

  .trcountdown-container {
    flex-direction: column;
    margin-right: 0;
    margin-top: 20px;
  }

  .trcountdown-timer {
    gap: 10px;
  }
}



.trconference-details {
  background-color: none;
  padding: 50px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.trimage-container {
  margin-top: 10px;
}

.trimage-container img {
  width: 100%;
  max-width: 1038px;
  height: 500px;
  border-radius: 10px;
}



.trtext-container {
  margin-top: 30px;
  max-width: 900px; 
  margin: 0 auto;
  padding: 30px; 
  text-align: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  border-radius: 10px; 
}

.trtext-container h2 {
  color: #003366; 
  font-size: 34px; 
  font-weight: bold;
  margin-bottom: 24px; 
  letter-spacing: 1px; 
}
.trtext-container p {
  font-size: 20px;
  line-height: 1.8; 
  color: #333; 
  margin-bottom: 18px;
  text-align: justify; 
}
.trtext-container strong {
  font-weight: 700; 
  color: #222; 
}
.trtext-container br {
  line-height: 1.8; 
}
@media (max-width: 1200px) {
  .trtext-container {
    padding: 25px; 
  }
  .trtext-container h2 {
    font-size: 30px; 
  }
  .trtext-container p {
    font-size: 18px; 
  }
}
@media (max-width: 992px) {
  .trtext-container {
    padding: 20px; 
  }

  .trtext-container h2 {
    font-size: 28px;
  }

  .trtext-container p {
    font-size: 17px; 
  }
}
@media (max-width: 768px) {
  .trtext-container {
    padding: 15px; 
  }

  .trtext-container h2 {
    font-size: 26px; 
  }

  .trtext-container p {
    font-size: 16px; 
    line-height: 1.6; 
  }
}







/* Scientific Sessions Section */
.trscientific-sessions {
  padding: 50px 100px;
  background-color: #f4f4f4;
  text-align: center;
  margin-top: 1px;
}

.trscientific-sessions h1 {
  font-size: 3rem;
  color: #2d5e7e;
  margin-bottom: 20px;
  position: relative;
}

.trscientific-sessions h1::after {
  content: "";
  width: 60px;
  height: 2px;
  background: #f4f4f4;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.trscientific-sessions ul {
  list-style: none;
  padding: 0;
  max-width: 900px;
  margin: 0 auto;
}

.trscientific-sessions ul li {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.trscientific-sessions ul li:hover {
  transform: scale(1.02);
  background-color: #eef4fa;
}

/* Venue Section */
.trvenue-section {
  padding: 40px;
  text-align: center;
  margin-top: 1px;
}

.trvenue-section h2 {
  font-size: 52px;
  color: #2d5e7e;
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
}

.trvenue-section h2::after {
  content: "";
  width: 60px;
  height: 3px;
  background: #f4f4f4;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.trvenue-section p {
  font-size: 24px;
  color: #333;
}




.trscientific-agenda {
  margin-top: 1px;
  text-align: center;
  padding: 40px;
  background-color: #f4f4f4;
  color: #f1f1f1;
}

.trscientific-agenda h2 {
  font-size: 36px;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #61dafb;
}

.trday-buttons {
  margin-bottom: 30px;
}
.trday-buttons button {
  background-color: #61dafb;
  border: none;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
  transition: background-color 0.3s, transform 0.3s;
}
.trday-buttons button:hover {
  background-color: #21a1f1;
  transform: scale(1.05);
}

.trday-buttons .active {
  background-color: #333;
  color: #61dafb;
}

.trschedule-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  background-color: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
}

.trschedule-table th,
.trschedule-table td {
  padding: 15px;
  text-align: left;
  font-size: 18px;
}

.trschedule-table th {
  background-color: #61dafb;
  color: white;
  font-size: 20px;
}

.trschedule-table td {
  color: #333;
}

.trschedule-table tr:nth-child(even) {
  background-color: #f7f7f7;
}

.trschedule-table tr:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

.trschedule-table tr:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media (max-width: 768px) {
  .trscientific-agenda {
    padding: 20px;
  }

  .trschedule-table {
    width: 100%;
  }

  .trday-buttons button {
    font-size: 14px;
    padding: 10px 18px;
  }

  .trschedule-table th,
  .trschedule-table td {
    font-size: 16px;
    padding: 10px;
  }
}