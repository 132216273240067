/* General Styling (Desktop Default) */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* This prevents horizontal scrolling */
}
.mioverlay-side {
  position: relative;
  width: 90%;
  height: 600px;
  margin-top: 0;
  background-color: #17c3d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 100px;
  top: auto;
}
.mioverlay-text {
  color: #fff;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
}
.mioverlay-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.mioverlay-text p {
  font-size: 1.3rem;
  margin-bottom: 30px;
}

.mievent-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.mievent-date,
.mievent-location {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 1.5rem;
  color: #fff;
}

.mibutton-group {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.mievent-button {
  text-decoration: none;
  padding: 12px 25px;
  font-size: 1.2rem;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.mievent-button:hover {
  background-color: #45a049;
  transform: translateY(-5px);
}

.mievent-button:active {
  transform: translateY(2px);
}



.micountdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  padding: 20px 0;
  color: white;
}

.micountdown-timer {
  display: flex;
  justify-content: center;
  gap: 20px;
  animation: fadeIn 1s ease;
}

.mitime-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  animation: bounce 1.5s infinite ease-in-out;
}

.mitime-box span:first-child {
  font-size: 2.8rem;
  font-weight: bold;
  color: #fff;
  animation: fadeNumber 1s infinite alternate;
}

.mitime-box span:last-child {
  font-size: 1.1rem;
  margin-top: 10px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeNumber {
  0% {
    color: rgba(255, 255, 255, 0.8);
  }
  100% {
    color: #fff;
  }
}
@media (max-width: 1024px) {
  .mioverlay-side {
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 50px 40px;
  }

  .mioverlay-text {
    text-align: center;
    padding: 20px;
  }

  .mioverlay-text h1 {
    font-size: 2rem;
  }

  .mioverlay-text p {
    font-size: 1.1rem;
  }

  .micountdown-container {
    margin-right: 0;
    padding: 20px 0;
  }

  .micountdown-timer {
    gap: 15px;
  }

  .mitime-box {
    padding: 40px;
  }

  .mitime-box span:first-child {
    font-size: 2.5rem;
  }

  .mitime-box span:last-child {
    font-size: 1rem;
  }

  .mievent-button {
    padding: 12px 20px;
  }
}
@media (max-width: 768px) {
  .mioverlay-side {
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mioverlay-text {
    text-align: center;
  }

  .mioverlay-text h1 {
    font-size: 1.8rem;
  }

  .mioverlay-text p {
    font-size: 1rem;
  }

  .micountdown-container {
    padding: 10px 0;
    width: 100%;
  }

  .micountdown-timer {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }

  .mitime-box {
    padding: 30px;
  }

  .mitime-box span:first-child {
    font-size: 2rem;
  }

  .mitime-box span:last-child {
    font-size: 0.9rem;
  }

  .mievent-button {
    padding: 10px 18px;
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .mioverlay-side {
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mioverlay-text {
    text-align: center;
  }
  .mioverlay-text h1 {
    font-size: 1.5rem;
  }

  .mioverlay-text p {
    font-size: 0.9rem;
  }

  .micountdown-container {
    padding: 10px 0;
    width: 100%;
  }
  .micountdown-timer {
    flex-direction: row;
    justify-content: space-around;
    gap: 5px;
  }

  .mitime-box {
    padding: 20px;
    width: 70px;
  }

  .mitime-box span:first-child {
    font-size: 1.6rem;
  }

  .mitime-box span:last-child {
    font-size: 0.7rem;
  }

  .mievent-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
} 


  .miconference-details {
    background-color: none;
    padding: 50px;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
  }
  
  .miimage-container {
    margin-top: 10px;
  }
  
  .miimage-container img {
    width: 100%;
    max-width: 1038px;
    height: 500px;
    border-radius: 10px;
  }
  
  
  .mitext-container {
    margin-top: 30px;
    max-width: 900px; 
    margin: 0 auto;
    padding: 30px; 
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    border-radius: 10px; 
  }
  
  .mitext-container h2 {
    color: #003366; 
    font-size: 34px; 
    font-weight: bold;
    margin-bottom: 24px; 
    letter-spacing: 1px; 
  }
  .mitext-container p {
    font-size: 20px;
    line-height: 1.8; 
    color: #333; 
    margin-bottom: 18px;
    text-align: justify; 
  }
  .mitext-container strong {
    font-weight: 700; 
    color: #222; 
  }
  .mitext-container br {
    line-height: 1.8; 
  }
  @media (max-width: 1200px) {
    .mitext-container {
      padding: 25px; 
    }
    .mitext-container h2 {
      font-size: 30px; 
    }
    .mitext-container p {
      font-size: 18px; 
    }
  }
  @media (max-width: 992px) {
    .mitext-container {
      padding: 20px; 
    }
  
    .mitext-container h2 {
      font-size: 28px;
    }
  
    .mitext-container p {
      font-size: 17px; 
    }
  }
  @media (max-width: 768px) {
    .mitext-container {
      padding: 15px; 
    }
  
    .mitext-container h2 {
      font-size: 26px; 
    }
  
    .mitext-container p {
      font-size: 16px; 
      line-height: 1.6; 
    }
  }
  



  
  .mitopics-sessions {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
  }
  
  .mitopics-sessions h1 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
  }
  
  .mitopics-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  
  .mitopics-card {
    background-color: #f9f9f9;
    flex: 1 1 calc(30% - 80px); 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .mitopics-card:hover {
    transform: translateY(-10px); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  .mitopics-card h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
    text-align: left;
  }
  .mitopics-card ul {
    list-style-type: none;
    padding-left: 0;
  }
  .mitopics-card ul li {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    .mitopics-card {
      flex: 1 1 calc(33.33% - 20px); 
    }
  }
  
  @media (max-width: 768px) {
    .mitopics-card {
      flex: 1 1 calc(50% - 20px); 
    }
  }
  
  @media (max-width: 480px) {
    .mitopics-card {
      flex: 1 1 100%; 
    }
  } 
  .mivenue-section {
    padding: 40px;
    text-align: center;
    margin-top: 1px;
  }
  
  .mivenue-section h2 {
    font-size: 52px;
    color: #2d5e7e;
    margin-bottom: 10px;
    margin-top: 20px;
    position: relative;
  }
  .mivenue-section h2::after {
    content: "";
    width: 60px;
    height: 3px;
    background: #f4f4f4;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .mivenue-section p {
    font-size: 24px;
    color: #333;
  }


  .miscientific-agenda {
    margin-top: 1px;
    text-align: center;
    padding: 40px;
    background-color: #f4f4f4;
    color: #f1f1f1;
  }
  
  .miscientific-agenda h2 {
    font-size: 36px;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #61dafb;
  }
  
  .miday-buttons {
    margin-bottom: 30px;
  }
  .miday-buttons button {
    background-color: #61dafb;
    border: none;
    color: #fff;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
    transition: background-color 0.3s, transform 0.3s;
  }
  .miday-buttons button:hover {
    background-color: #21a1f1;
    transform: scale(1.05);
  }
  
  .miday-buttons .active {
    background-color: #333;
    color: #61dafb;
  }
  
  .mischedule-table {
    width: 90%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: #f1f1f1;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .mischedule-table th,
  .mischedule-table td {
    padding: 15px;
    text-align: left;
    font-size: 18px;
  }
  
  .mischedule-table th {
    background-color: #61dafb;
    color: white;
    font-size: 20px;
  }
  
  .mischedule-table td {
    color: #333;
  }
  
  .mischedule-table tr:nth-child(even) {
    background-color: #f7f7f7;
  }
  
  .mischedule-table tr:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
  
  .mischedule-table tr:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @media (max-width: 768px) {
    .miscientific-agenda {
      padding: 20px;
    }
  
    .mischedule-table {
      width: 100%;
    }
  
    .miday-buttons button {
      font-size: 14px;
      padding: 10px 18px;
    }
  
    .mischedule-table th,
    .mischedule-table td {
      font-size: 16px;
      padding: 10px;
    }
  }