.rdeventsover {
    position: relative;
    width: 92%;
    height: 600px;
    background-color: #154173;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    margin-top: 0;
    margin: 0;
  }
  
  .rdeventsoverlay-text {
    color: #fff;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
  }
  
  .rdeventsoverlay-text h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  
  .rdeventsoverlay-text p {
    font-size: 1.3rem;
    margin-bottom: 30px;
  }
  
  .rdevent-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .rdevent-date,
  .rdevent-location {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .rdicon {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .rdbutton-group {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
  
  .rdevent-button {
    text-decoration: none;
    padding: 12px 25px;
    font-size: 1.2rem;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .rdevent-button:hover {
    background-color: #45a049;
    transform: translateY(-5px);
  }
  
  .rdevent-button:active {
    transform: translateY(2px);
  }
  
  .rdcountdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    padding: 2 0px;
    color: white;
  }
  
  .rdcountdown-timer {
    display: flex;
    justify-content: center;
    gap: 10px;
    animation: fadeIn 1s ease;
  }
  
  .rdtime-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .rdtime-box span:first-child {
    font-size: 2.8rem;
    font-weight: bold;
    color: #fff;
    animation: fadeNumber 1s infinite alternate;
  }
  
  .rdtime-box span:last-child {
    font-size: 1.1rem;
    margin-top: 10px;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes fadeNumber {
    0% {
      color: rgba(255, 255, 255, 0.8);
    }
    100% {
      color: #fff;
    }
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .rdeventsover {
      flex-direction: column;
      padding: 0 50px;
      height: auto;
    }
  
    .rdeventsoverlay-text h1 {
      font-size: 2.2rem;
    }
  
    .rdeventsoverlay-text p {
      font-size: 1.1rem;
    }
  
    .rdevent-date,
    .rdevent-location {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 1024px) {
    .rdeventsover {
      padding: 0 30px;
    }
  
    .rdeventsoverlay-text h1 {
      font-size: 2rem;
    }
  
    .rdeventsoverlay-text p {
      font-size: 1rem;
    }
  
    .rdevent-details {
      gap: 5px;
    }
  
    .rdbutton-group {
      flex-direction: column;
      gap: 10px;
    }
  
    .rdevent-button {
      padding: 10px 20px;
    }
  
    .rdcountdown-timer {
      gap: 15px;
    }
  
    .rdtime-box {
      padding: 40px;
    }
  
    .rdtime-box span:first-child {
      font-size: 2.4rem;
    }
  
    .rdtime-box span:last-child {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .rdeventsover {
      padding: 0 20px;
    }
  
    .rdeventsoverlay-text h1 {
      font-size: 1.8rem;
    }
  
    .rdeventsoverlay-text p {
      font-size: 0.9rem;
    }
  
    .rdevent-details {
      gap: 5px;
    }
  
    .rdcountdown-timer {
      gap: 10px;
      flex-direction: row;
    }
  
    .rdtime-box {
      padding: 30px;
    }
  
    .rdtime-box span:first-child {
      font-size: 2rem;
    }
  
    .rdtime-box span:last-child {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .rdeventsover {
      padding: 0 10px;
    }
  
    .rdeventsoverlay-text h1 {
      font-size: 1.5rem;
    }
  
    .rdeventsoverlay-text p {
      font-size: 0.8rem;
    }
  
    .rdevent-details {
      gap: 5px;
    }
  
    .rdevent-button {
      padding: 8px 15px;
      font-size: 1rem;
    }
  
    .rdcountdown-container {
      margin-right: 0;
    }
  
    .rdtime-box {
      padding: 10px;
    }
  
    .rdtime-box span:first-child {
      font-size: 1.8rem;
    }
  
    .rdtime-box span:last-child {
      font-size: 0.8rem;
    }
  }
  
    

    .rdconference-details {
      background-color: none;
      padding: 50px;
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333;
    }
    
    .imagerd-container {
      margin-top: 10px;
    }
    
    .imagerd-container img {
      width: 100%;
      max-width: 1038px;
      height: 500px;
      border-radius: 10px;
    }
  
 


  .newtextrd-container {
    margin-top: 30px;
    max-width: 900px;
    margin: 0 auto; 
    padding: 30px; 
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif; 
    border-radius: 10px; 
  }
  
  .newtextrd-container h2 {
    color: #003366; 
    font-size: 34px; 
    font-weight: bold;
    margin-bottom: 24px; 
    letter-spacing: 1px; 
  }
  
  .newtextrd-container p {
    font-size: 20px;
    line-height: 1.8; 
    color: #333; 
    margin-bottom: 18px;
    text-align: justify; 
  }
  
  .newtextrd-container strong {
    font-weight: 700; 
    color: #222; 
  }
  
  .newtextrd-container br {
    line-height: 1.8; 
  }
  
  
  @media (max-width: 1200px) {
    .newtextrd-container {
      padding: 25px; 
    }
  
    .newtextrd-container h2 {
      font-size: 30px; 
    }
  
    .newtextrd-container p {
      font-size: 18px; 
    }
  }
  
  @media (max-width: 992px) {
    .newtextrd-container {
      padding: 20px; 
    }
  
    .newtextrd-container h2 {
      font-size: 28px; 
    }
  
    .newtextrd-container p {
      font-size: 17px; 
    }
  }
  
  @media (max-width: 768px) {
    .newtextrd-container {
      padding: 15px; 
    }
  
    .newtextrd-container h2 {
      font-size: 26px; 
    }
  
    .newtextrd-container p {
      font-size: 16px; 
      line-height: 1.6; 
    }
  }



  
    .rdnewscientific-sessions {
      max-width: 1200px;
      margin: 0 auto;
      padding: 40px 20px;
      font-family: 'Helvetica Neue', Arial, sans-serif;
    }
    
    .rdnewscientific-sessions h1 {
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #333;
      margin-bottom: 40px;
    }
    
    .rdnewscientific-card-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
    }
    
    .rdnewscientific-card {
      background-color: #f9f9f9;
      flex: 1 1 calc(30% - 80px); 
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .rdnewscientific-card:hover {
      transform: translateY(-10px); 
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    
    .rdnewscientific-card h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #333;
      text-align: left;
    }
    
    .rdnewscientific-card ul {
      list-style-type: none;
      padding-left: 0;
    }
    
    .rdnewscientific-card ul li {
      font-size: 16px;
      color: #555;
      margin-bottom: 10px;
    }
    
    @media (max-width: 1024px) {
      .rdnewscientific-card {
        flex: 1 1 calc(33.33% - 20px); 
      }
    }
    
    @media (max-width: 768px) {
      .rdnewscientific-card {
        flex: 1 1 calc(50% - 20px); 
      }
    }
    
    @media (max-width: 480px) {
      .rdnewscientific-card {
        flex: 1 1 100%; 
      }
    } 
  
  
    /* Venue Section */
    .rdvenue-section {
      padding: 40px;
      text-align: center;
      margin-top: 1px;
    }
    
    .rdvenue-section h2 {
      font-size: 52px;
      color: #2d5e7e;
      margin-bottom: 10px;
      margin-top: 20px;
      position: relative;
    }
    
    .rdvenue-section h2::after {
      content: "";
      width: 60px;
      height: 3px;
      background: #f4f4f4;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .rdvenue-section p {
      font-size: 24px;
      color: #333;
    }
    

    .rdscientific-agenda {
      margin-top: 1px;
      text-align: center;
      padding: 40px;
      background-color: #f4f4f4;
      color: #f1f1f1;
    }
    
    .rdscientific-agenda h2 {
      font-size: 36px;
      margin-bottom: 30px;
      text-transform: uppercase;
      color: #61dafb;
    }
    
    .rdday-buttons {
      margin-bottom: 30px;
    }
    
    .rdday-buttons button {
      background-color: #61dafb;
      border: none;
      color: #fff;
      padding: 12px 24px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      margin: 5px;
      transition: background-color 0.3s, transform 0.3s;
    }
    
    .rdday-buttons button:hover {
      background-color: #21a1f1;
      transform: scale(1.05);
    }
    
    .rdday-buttons .active {
      background-color: #333;
      color: #61dafb;
    }
    
    .rdschedule-table {
      width: 90%;
      margin: 0 auto;
      border-collapse: collapse;
      background-color: #f1f1f1;
      border-radius: 8px;
      overflow: hidden;
    }
    
    .rdschedule-table th,
    .rdschedule-table td {
      padding: 15px;
      text-align: left;
      font-size: 18px;
    }
    
    .rdschedule-table th {
      background-color: #61dafb;
      color: white;
      font-size: 20px;
    }
    
    .rdschedule-table td {
      color: #333;
    }
    
    .rdschedule-table tr:nth-child(even) {
      background-color: #f7f7f7;
    }
    
    .rdschedule-table tr:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
    
    .rdschedule-table tr:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    
    /* Responsive Design */
    @media (max-width: 768px) {
      .rdscientific-agenda {
        padding: 20px;
      }
    
      .rdschedule-table {
        width: 100%;
      }
    
      .rdday-buttons button {
        font-size: 14px;
        padding: 10px 18px;
      }
    
      .rdschedule-table th,
      .rdschedule-table td {
        font-size: 16px;
        padding: 10px;
      }
    }
    