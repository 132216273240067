body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}
.olddetailsover {
  position: relative;
  width: 92%;
  height: 600px;
  background-color: #bf9325;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  margin-top: 0;
  margin: 0;
}

.olddetailsoverlay-text {
  color: #fff;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
}

.olddetailsoverlay-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.oldevent-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.oldevent-date,
.oldevent-location {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.old-icon {
  font-size: 1.5rem;
  color: #fff;
}

.oldbutton-group {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.oldevent-button {
  text-decoration: none;
  padding: 12px 25px;
  font-size: 1.2rem;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.oldevent-button:hover {
  background-color: #45a049;
  transform: translateY(-5px);
}

.oldevent-button:active {
  transform: translateY(2px);
}

.oldcountdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 70px;
  padding: 2 0px;
  color: white;
}

.oldcountdown-timer {
  display: flex;
  justify-content: center;
  gap: 10px;
  animation: fadeIn 1s ease;
}

.old-time-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  animation: bounce 1.5s infinite ease-in-out;
}

.old-time-box span:first-child {
  font-size: 2.8rem;
  font-weight: bold;
  color: #fff;
  animation: fadeNumber 1s infinite alternate;
}

.old-time-box span:last-child {
  font-size: 1.1rem;
  margin-top: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeNumber {
  0% {
    color: rgba(255, 255, 255, 0.8);
  }
  100% {
    color: #fff;
  }
}

/* Responsive styles */
@media (max-width: 1200px) {
  .olddetailsover {
    flex-direction: column;
    padding: 0 50px;
    height: auto;
  }

  .olddetailsoverlay-text h1 {
    font-size: 2.2rem;
  }

  .olddetailsoverlay-text p {
    font-size: 1.1rem;
  }

  .oldevent-date,
  .oldevent-location {
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .olddetailsover {
    padding: 0 30px;
  }

  .olddetailsoverlay-text h1 {
    font-size: 2rem;
  }

  .olddetailsoverlay-text p {
    font-size: 1rem;
  }

  .oldevent-details {
    gap: 5px;
  }

  .oldbutton-group {
    flex-direction: column;
    gap: 10px;
  }

  .oldevent-button {
    padding: 10px 20px;
  }

  .oldcountdown-timer {
    gap: 15px;
  }

  .old-time-box {
    padding: 40px;
  }

  .old-time-box span:first-child {
    font-size: 2.4rem;
  }

  .old-time-box span:last-child {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .olddetailsover {
    padding: 0 20px;
  }

  .olddetailsoverlay-text h1 {
    font-size: 1.8rem;
  }

  .olddetailsoverlay-text p {
    font-size: 0.9rem;
  }

  .oldevent-details {
    gap: 5px;
  }

  .oldcountdown-timer {
    gap: 10px;
    margin-left: 70px;
    flex-direction: row;
  }

  .old-time-box {
    padding: 30px;
  }

  .old-time-box span:first-child {
    font-size: 2rem;
  }

  .old-time-box span:last-child {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .olddetailsover {
    padding: 0 10px;
  }

  .olddetailsoverlay-text h1 {
    font-size: 1.5rem;
  }

  .olddetailsoverlay-text p {
    font-size: 0.8rem;
  }

  .oldevent-details {
    gap: 5px;
  }

  .oldevent-button {
    padding: 8px 15px;
    font-size: 1rem;
  }

  .oldcountdown-container {
    margin-right: 0;
  }

  .old-time-box {
    padding: 10px;
  }

  .old-time-box span:first-child {
    font-size: 1.8rem;
  }

  .old-time-box span:last-child {
    font-size: 0.8rem;
  }
}







   .newconferences-details {
     background-color: none;
     padding: 50px;
     margin-top: -20px;
     display: flex;
     flex-direction: column;
     align-items: center;
     color: #333;
    }
      
  .newconfimage-container {
     margin-top: 10px;
    }
      
  .newconfimage-container img {
     width: 100%;
     max-width: 1038px;
     height: 500px;
     border-radius: 10px;
   }

  .newconftext-container {
    margin-top: 30px;
    max-width: 900px; 
    margin: 0 auto;
    /* margin-left: 50px; */
    padding: 30px; 
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    border-radius: 10px; 
  }
  
  .newconftext-container h2 {
    color: #003366; 
    font-size: 34px; 
    font-weight: bold;
    margin-bottom: 24px; 
    letter-spacing: 1px; 
  }
  .newconftext-container p {
    font-size: 20px;
    line-height: 1.8; 
    color: #333; 
    margin-bottom: 18px;
    text-align: justify; 
  }
  .newconftext-container strong {
    font-weight: 700; 
    color: #222; 
  }
  .newconftext-container br {
    line-height: 1.8; 
  }
  @media (max-width: 1200px) {
    .newconftext-container {
      padding: 25px; 
    }
    .newconftext-container h2 {
      font-size: 30px; 
    }
    .newconftext-container p {
      font-size: 18px; 
    }
  }
  @media (max-width: 992px) {
    .newconftext-container {
      padding: 20px; 
    }
  
    .newconftext-container h2 {
      font-size: 28px;
    }
  
    .newconftext-container p {
      font-size: 17px; 
    }
  }
  @media (max-width: 768px) {
    .newconftext-container {
      padding: 15px; 
    }
  
    .newconftext-container h2 {
      font-size: 26px; 
    }
  
    .newconftext-container p {
      font-size: 16px; 
      line-height: 1.6; 
    }
  }

    
   .scientific-newconferences {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
  }
  .scientific-newconferences h1 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
  }
  .scientific-newconferences-card {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  .scientific-newcard {
    background-color: #f9f9f9;
    flex: 1 1 calc(30% - 80px); /* Four cards per row */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .scientific-newcard:hover {
    transform: translateY(-10px); /* Interactive hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  .scientific-newcard h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
    text-align: left;
  }
  .scientific-newcard ul {
    list-style-type: none;
    padding-left: 0;
  }
  .scientific-newcard ul li {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    .scientific-newcard {
      flex: 1 1 calc(33.33% - 20px); 
    }
  }
  @media (max-width: 768px) {
    .scientific-newcard {
      flex: 1 1 calc(50% - 20px);
    }
  }
  @media (max-width: 480px) {
    .scientific-newcard {
      flex: 1 1 100%; 
    }
  } 
    .newvenue-confsection {
      padding: 40px;
      text-align: center;
      margin-top: 1px;
    }
    
    .newvenue-confsection h2 {
      font-size: 52px;
      color: #2d5e7e;
      margin-bottom: 10px;
      margin-top: 20px;
      position: relative;
    }
    
    .newvenue-confsection h2::after {
      content: "";
      width: 60px;
      height: 3px;
      background: #f4f4f4;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .newvenue-confsection p {
      font-size: 24px;
      color: #333;
    }


    .newconfscientific-agenda {
      margin-top: 1px;
      text-align: center;
      padding: 40px;
      background-color: #f4f4f4;
      color: #f1f1f1;
    }
    
    .newconfscientific-agenda h2 {
      font-size: 36px;
      margin-bottom: 30px;
      text-transform: uppercase;
      color: #61dafb;
    }
    
    .newconfday-buttons {
      margin-bottom: 30px;
    }
    .newconfday-buttons button {
      background-color: #61dafb;
      border: none;
      color: #fff;
      padding: 12px 24px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      margin: 5px;
      transition: background-color 0.3s, transform 0.3s;
    }
    .newconfday-buttons button:hover {
      background-color: #21a1f1;
      transform: scale(1.05);
    }
    
    .newconfday-buttons .active {
      background-color: #333;
      color: #61dafb;
    }
    

    .newconferenceschedule {
      width: 90%;
      margin: 0 auto;
      border-collapse: collapse;
      background-color: #f1f1f1;
      border-radius: 8px;
      overflow: hidden;
    }
    
    .newconferenceschedule th,
    .newconferenceschedule td {
      padding: 15px;
      text-align: left;
      font-size: 18px;
    }
    
    .newconferenceschedule th {
      background-color: #61dafb;
      color: white;
      font-size: 20px;
    }
    
    .newconferenceschedule td {
      color: #333;
    }
    
    .newconferenceschedule tr:nth-child(even) {
      background-color: #f7f7f7;
    }
    
    .newconferenceschedule tr:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
    
    .newconferenceschedule tr:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    @media (max-width: 768px) {
      .newconfscientific-agenda {
        padding: 20px;
      }
    
      .newconferenceschedule {
        width: 100%;
      }
    
      .newconfday-buttons button {
        font-size: 14px;
        padding: 10px 18px;
      }
    
      .newconferenceschedule th,
      .newconferenceschedule td {
        font-size: 16px;
        padding: 10px;
      }
    }
    
    .registration-sec {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 100vh;
      padding: 50px 20px;
      background: linear-gradient(to bottom, #044a6b, #0a3c5d);
  }
  
  .intro-sec h1 {
      font-size: 2.8em;
      font-weight: bold;
      margin-bottom: 20px;
      color: #FFFFFF;
      animation: fadeInDown 1s ease-in-out;
  }
  
  .intro-sec p {
      font-size: 1.2em;
      margin: 10px 0;
      color: #E0E0E0;
      animation: fadeInUp 1s ease-in-out;
  }
  
  .registration-card-new {
      display: grid;
      grid-template-columns: repeat(3, 1fr); 
      gap: 70px; 
      max-width: 1200px; 
      margin: 0 auto;
      padding: 0 20px; 
      justify-content: center; 
      animation: fadeIn 1.5s ease-in-out;
  }
  
  .registration-newsec {
      background-color: #F7F9FC;
      color: #0D3B66;
      border-radius: 10px;
      padding: 25px;
      width: 100%;
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
      text-align: center;
      transform: scale(1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
  }
  
  .registration-newsec:hover {
      transform: scale(1.08);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  .registration-newsec h3 {
      font-size: 1.8em;
      margin-bottom: 15px;
      color: #0056b3;
      text-transform: uppercase;
      letter-spacing: 1.5px;
  }
  
  .registration-newsec ul {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;
  }
  
  .registration-newsec ul li {
      font-size: 1em;
      margin: 8px 0;
      color: #3C4858;
  }
  
  .registration-newsec p {
      font-size: 1.6em;
      color: #FF5A5F;
      font-weight: bold;
      margin-bottom: 20px;
  }
  
  .registration-newsec button {
      background-color: #007BFF;
      color: #FFFFFF;
      border: none;
      padding: 12px 25px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease;
  }
  
  .registration-newsec button:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
  }
  
  @keyframes fadeIn {
      from {
          opacity: 0;
          transform: translateY(20px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @keyframes fadeInDown {
      from {
          opacity: 0;
          transform: translateY(-30px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @keyframes fadeInUp {
      from {
          opacity: 0;
          transform: translateY(30px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @media (max-width: 1024px) {
      .registration-card-new {
          grid-template-columns: repeat(2, 1fr); 
          gap: 60px; 
          padding: 0 30px; 
      }
  }
  
  @media (max-width: 768px) {
      .registration-card-new {
          grid-template-columns: 1fr; 
          gap: 40px; 
          padding: 0 15px; 
      }
  }
  
  @media (max-width: 480px) {
      .registration-card-new {
          grid-template-columns: 1fr; 
          gap: 30px; 
          padding: 0 10px; 
      }
  }
  