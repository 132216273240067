.events-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
  margin: 0 auto;
  padding: 2rem;
  background-color: #38afda;
  background-image: url('../images/home11.jpg');
  background-size: cover;
  background-position: center;
}

.card {
  flex: 1 1 calc(33.33% - 30px); /* Three cards per row */
  max-width: calc(33.33% - 30px); /* Max width for three cards */
  margin: 15px; /* Spacing between cards */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.card-content {
  padding: 10px;
}

.card h2 {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333;
}

.date,
.location,
.description {
  margin: 5px 0;
  color: #555;
  font-size: 0.9rem;
}

.card-buttons {
  margin-top: 10px;
}

.details-btn,
.register-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.details-btn:hover,
.register-btn:hover {
  background-color: #0056b3;
}

.register-btn {
  background-color: #28a745;
}

.register-btn:hover {
  background-color: #218838;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .card {
    flex: 1 1 calc(33.33% - 30px); /* Three cards per row for larger tablets */
    max-width: calc(33.33% - 30px); /* Consistent size on larger screens */
  }
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 30px); /* Two cards per row for smaller tablets */
    max-width: calc(50% - 30px);
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 1 calc(100% - 30px); /* One card per row for mobile */
    max-width: calc(100% - 30px);
    margin: 10px 0;
  }
}
