/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5; 
  color: #333;
}

.contact-us-container {
  max-width: 100%; 
  margin: 0 auto;
  padding: 0;
  background: transparent; 
  border-radius: 10px;
  box-shadow: none; 
}

.contactpage {
  text-align: center;
  padding: 40px;
  background-color: #0c2d50; 
  color: white;
  border-radius: 10px 10px 0 0;
}

.contacthero h1 {
  margin: 0;
  font-size: 2.5rem;
}

.contacthero h4 a {
  margin: 0 5px;
  color: white;
  transition: color 0.3s ease;
}

.contacthero h4 a:hover {
  color: #FFD700; /* Gold color on hover */
}

/* Contact Content */
.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

/* Text Section */
.text-section {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ccc; 
}

.text-section h2 {
  font-size: 1.8rem;
}

.text-section p {
  margin: 10px 0;
}


.form-section {
  flex: 1;
  padding: 20px;
  margin-right: 90px;
}

.form-section h2 {
  font-size: 1.8rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007BFF; 
  outline: none;
}

button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3; 
}

.status-message {
  margin-top: 10px;
  font-weight: bold;
  color: green; 
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .contact-us-container {
    max-width: 95%;
    padding: 0 15px;
  }

  .contact-content {
    flex-direction: column;
  }

  .text-section, .form-section {
    width: 100%;
    padding: 15px;
    flex: none;
  }

  .text-section {
    border-right: none;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .contactpage {
    padding: 30px 15px;
  }

  .contacthero h1 {
    font-size: 2rem;
  }

  .contacthero h4 {
    font-size: 0.9rem;
  }

  .text-section h2,
  .form-section h2 {
    font-size: 1.6rem;
  }

  .form-group {
    margin-bottom: 12px;
  }

  .form-group input,
  .form-group textarea {
    padding: 9px;
    font-size: 0.95rem;
  }

  button {
    width: 95%;
    padding: 12px;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .contactpage {
    padding: 20px 10px;
  }

  .contacthero h1 {
    font-size: 1.5rem;
  }

  .contacthero h4 {
    font-size: 0.8rem;
  }

  .text-section h2,
  .form-section h2 {
    font-size: 1.4rem;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
    font-size: 0.85rem;
  }

  button {
    font-size: 0.9rem;
    padding: 10px;
  }
}

@media (max-width: 320px) {
  .contactpage {
    padding: 15px 5px;
  }

  .contacthero h1 {
    font-size: 1.3rem;
  }

  .contacthero h4 {
    font-size: 0.75rem;
  }

  .text-section h2,
  .form-section h2 {
    font-size: 1.2rem;
  }

  .form-group input,
  .form-group textarea {
    padding: 6px;
    font-size: 0.8rem;
  }

  button {
    font-size: 0.85rem;
    padding: 8px;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .contact-content {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .text-section, 
  .form-section {
    width: 50%;
    padding: 10px;
  }
}