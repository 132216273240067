.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: background-color 0.3s ease-in-out;
}
.navbar-logo {
    flex: 1; 
}
.logo-image {
    height: 70px; 
    width: auto; 
}
.nav-links {
    list-style: none;
    display: flex; 
    gap: 2rem; 
    margin: 0; 
    margin-right: 60px;
    padding: 0;
    font-weight: bold; 
}
.nav-links li {
    display: inline-block; 
}
.nav-links a {
    text-decoration: none; 
    color: #333; 
    font-size: 1.2rem;
    position: relative;
    transition: color 0.3s ease, transform 0.3s ease; 
}
.nav-links a:hover,
.nav-links a:focus {
    color: #007bff; 
    transform: scale(1.1); 
}
.search-bar {
    display: flex; 
    align-items: center;
    gap: 0.5rem; 
    flex-shrink: 0; 
    margin-bottom: 0px;
}
.search-bar input {
    padding: 0.5rem; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    font-size: 1rem; 
}
.search-bar button {
    padding: 0.5rem; 
    background-color: #007BFF; 
    color: white; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
}
.search-bar button i {
    font-size: 1.2rem; 
}

.search-bar button:hover {
    background-color: #04070b;
}
.hamburger {
    display: none; 
    cursor: pointer; 
    margin-right: 50px; 
}
.hamburger i {
    font-size: 1.8rem; 
}
.nav-links.open {
    display: block; 
}
@media screen and (max-width: 768px) {
    .navbar {
        flex-direction: row;
        align-items: center; 
        padding: 1rem; 
    }
    .nav-links {
        display: none; 
        flex-direction: column; 
        width: 100%; 
        gap: 1rem; 
        margin-top: 1rem; 
    }
    .nav-links.open {
        display: flex; 
    }
    .nav-links a {
        padding: 0.5rem; 
        text-align: center; 
        width: 100%; 
        background-color: #f5f5f5; 
        border-radius: 5px; 
    }
    .hamburger {
        display: block; 
    }
}
@media screen and (max-width: 480px) {
    .nav-links a {
        font-size: 1rem; 
    }
    .search-bar {
        margin-top: 1rem; 
    }
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    width: 100vw; 
    height: 100vh; 
    overflow: hidden; 
}

.video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    transform: translate(-50%, -50%); 
    z-index: -1; 
}

.text-overlay {
    position: relative; 
    color: #fff;
    text-align: center;
    z-index: 1; 
    width: 90%; 
    max-width: 800px; 
}

.text-overlay h1 {
    font-size: 3rem; 
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); 
    animation: fadeIn 1.5s ease-in-out; 
}

.text-overlay h3 {
    font-size: 1.7rem;
    margin-top: 10px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); 
    letter-spacing: 2px; 
    white-space: normal; 
    overflow: visible; 
    text-overflow: clip; 
    animation: fadeIn 1.5s ease-in-out 0.5s; 
}

@media (max-width: 1200px) {
    .text-overlay h1 {
        font-size: 2.5rem; 
    }
    .text-overlay h3 {
        font-size: 1.6rem; 
    }
}

@media (max-width: 992px) {
    .text-overlay h1 {
        font-size: 2rem;
    }
    .text-overlay h3 {
        font-size: 1.5rem; 
        letter-spacing: 1.5px; 
    }
}

@media (max-width: 768px) {
    .text-overlay h1 {
        font-size: 1.8rem; 
    }
    .text-overlay h3 {
        font-size: 1.4rem; 
        margin-top: 8px; 
    }
}

@media (max-width: 480px) {
    .text-overlay h1 {
        font-size: 1.5rem; 
    }
    .text-overlay h3 {
        font-size: 1.2rem; 
        letter-spacing: 1px; 
        margin-top: 5px;
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.about-section {
    padding: 40px 20px;
    margin: 20px auto;
    color: #333;
    max-width: 1100px; 
    background-color: transparent; 
    border-radius: 0; 
}
.about-content {
    max-width: 1200px; 
    margin: 0 auto;
}
.about-section h2 {
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center; 
    border-bottom: 2px solid #333; 
    display: inline-block; 
    padding-bottom: 10px;
}
.about-section p {
    font-size: 1.5rem;
    line-height: 1.8;
    text-align: justify; 
    margin-bottom: 20px;
}
.read-more-button {
    padding: 12px 24px;
    background-color: #f0a500;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.read-more-button:hover {
    background-color: #9f9786;
    transform: translateY(-3px); 
}

@media (max-width: 1200px) {
    .about-section {
        padding: 30px 50px;
    }
    .about-section h2 {
        font-size: 2.2rem;
    }
    .about-section p {
        font-size: 1.4rem;
    }
}

@media (max-width: 992px) {
    .about-section {
        padding: 20px 40px;
    }
    .about-section h2 {
        font-size: 2rem;
    }
    .about-section p {
        font-size: 1.3rem;
    }
}

@media (max-width: 768px) {
    .about-section {
        padding: 20px 20px;
    }
    .about-section h2 {
        font-size: 1.8rem;
    }
    .about-section p {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .about-section {
        padding: 15px;
    }
    .about-section h2 {
        font-size: 1.5rem;
    }
    .about-section p {
        font-size: 1rem;
    }
    .read-more-button {
        padding: 8px 16px;
        font-size: 1rem;
    }
}

.call-media {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    max-width: 600px; 
    margin: 20px auto; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: box-shadow 0.3s ease;
    padding: 20px; 
    flex-wrap: wrap; 
}
.call-media:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.call-media__icon {
    width: 160px; 
    height: 160px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    border-radius: 50%; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease;
}
.call-media__icon:hover {
    transform: scale(1.05);
}
.call-media__icon img {
    max-width: 90%; 
    max-height: 90%; 
    object-fit: contain;
    border-radius: 50%; 
}
.call-media__label {
    font-weight: 600; 
    font-size: 1.3rem; 
    color: #555; 
    margin-bottom: 5px;
}
.call-media__info {
    font-size: 1.2rem; 
    color: #666; 
}
.footer-mail {
    color: #0066cc; 
    text-decoration: none; 
    font-weight: 600;
}
.footer-mail:hover {
    text-decoration: underline; 
    color: #004a99; 
}
@media (max-width: 768px) {
    .about-section, .call-media {
        padding: 10px; 
        text-align: center; 
        max-width: 95%; 
    }
    .about-section h2 { 
        font-size: 1.8rem; 
    }
    .about-section p { 
        font-size: 1rem; 
    }
    .call-media {
        padding: 15px;
        max-width: 95%; 
        flex-direction: column; 
        align-items: center;
    }
    .call-media__icon {
        width: 120px;
        height: 120px;
        margin-bottom: 10px;
    }
    .call-media__label, .call-media__info {
        font-size: 1.1rem;
        text-align: center; 
    }
}
@media (max-width: 480px) {
    .call-media {
        max-width: 100%;
        padding: 10px;
    }
    .call-media__icon {
        width: 90px;
        height: 90px;
    }
    .call-media__label, .call-media__info {
        font-size: 1rem;
        text-align: center; 
    }
}

.upcoming-conferences-heading {
    font-size: 3.5rem;
    color: #222;
    text-align: center;
    margin: 40px 0;
    font-weight: 600;
}
.conference-page {
    padding: 40px 20px;
    background-color: transparent;
}
.conference-section {
    max-width: 1200px;
    margin: 0 auto;
}
.conference-container {
    display: flex;
    align-items: center;
    margin-top: 5px; 
    gap: 30px;
}
.conference-container.reverse-layout {
    flex-direction: row-reverse;
}
.conference-main-image {
    width: 40%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
}
.conference-main-image:hover {
    transform: scale(1.05);
}
.conference-details {
    flex: 1;
}
.conference-details h3 {
    font-size: 2.2rem;
    color: #185faf;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}
.conference-details p {
    font-size: 1.4rem;
    color: #555;
    margin-bottom: 20px;
}
.conference-dates {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.conference-date {
    text-align: center;
    flex: 1;
    transition: transform 0.3s ease;
}
.conference-date img {
    width: 150px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
}
.conference-date p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #333;
}
.conference-date:hover {
    transform: scale(1.05);
}
@media screen and (max-width: 1024px) {
    .conference-container {
        flex-direction: column;
        gap: 20px;
    }
    .conference-main-image {
        width: 100%;
    }
    .conference-dates {
        flex-direction: column;
    }
}
@media screen and (max-width: 768px) {
    .upcoming-conferences-heading {
        font-size: 2.5rem;
    }
    .conference-details h3 {
        font-size: 1.8rem;
    }
    .conference-details p {
        font-size: 1.3rem;
    }
}

.experts-heading {
    text-align: center;
    font-size: 3rem; 
    margin-top: 50px;
    color: #222; 
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.experts-wrapper {
    position: relative;
    margin: 30px auto;
    padding: 20px;
    max-width: 1200px;
    overflow: hidden;
}
.experts-section {
    display: flex;
    gap: 20px;
    scroll-behavior: smooth;
    overflow-x: scroll;
    padding-bottom: 20px;
    scroll-snap-type: x mandatory; 
}
.expert-card {
    flex: 0 0 380px; 
    height: 550px; 
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.4s, box-shadow 0.4s, filter 0.4s;
    scroll-snap-align: center; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.expert-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
    filter: brightness(1.15);
}
.expert-card img {
    width: 100%;
    height: 75%; 
    object-fit: cover;
    border-bottom: 4px solid #185faf;
    transition: transform 0.4s ease, opacity 0.4s ease;
}
.expert-card:hover img {
    transform: scale(1.05); 
    opacity: 0.95; 
}
.expert-details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%; 
    padding: 15px;
    background: rgba(0, 0, 0, 0.7); 
    color: white;
    text-align: center;
    transform: translateY(100%);
    transition: transform 0.4s ease;
}
.expert-card:hover .expert-details {
    transform: translateY(0);
    animation: flipFlopUp 0.8s ease-in-out forwards;
}

.expert-details h4 {
    font-size: 1.6rem; 
    margin: 5px 0;
    color: #fff;
    font-weight: 600;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.expert-details p {
    font-size: 1.2rem; 
    color: #eee; 
    margin: 0;
}
.scroll-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #185faf;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 50%; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: background-color 0.3s, transform 0.3s;
    width: 50px; 
    height: 50px; 
    font-size: 1.5rem; 
}
.scroll-btn:hover {
    background-color: #357ABD;
    transform: scale(1.1);
}
.scroll-btn.left {
    left: 10px; 
}
.scroll-btn.right {
    right: 10px;
}

@media (max-width: 768px) {
    .expert-card { 
        flex: 0 0 280px; 
    }
    .scroll-btn {
        padding: 8px; 
        width: 35px; 
        height: 35px; 
        font-size: 1.2rem; 
    }
}

@media (max-width: 480px) {
    .expert-card { 
        flex: 0 0 200px; 
    }
    .experts-heading { 
        font-size: 2.5rem; 
    }
    .scroll-btn {
        padding: 6px; 
        width: 30px;
        height: 30px; 
        font-size: 1rem; 
    }
}



.custom-container {
    font-family: Arial, sans-serif;
    background: linear-gradient(135deg, #e0f7fa, #a7ffeb);
    color: #333;
    text-align: center;
    padding: 100px 20px; 
}
.custom-title {
    font-size: 36px;
    color: #0d50c2;
    margin: 0; 
}
.custom-stats {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 40px;
    flex-wrap: wrap; 
}

.custom-stat {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
    flex: 1; 
    min-width: 150px; 
}
.custom-number {
    display: block;
    font-weight: bold;
    font-size: 1.2em;
}
.custom-stat-1 { color: #963014; }
.custom-stat-2 { color: #437027; }
.custom-stat-3 { color: #1f8f3d; }
.custom-stat-4 { color: #063694; }

@media (max-width: 600px) {
    .custom-container { padding: 50px 15px; }
    .custom-title { font-size: 24px; }
    .custom-stats { 
        flex-direction: column; 
        align-items: center; 
        gap: 20px; 
    }
    .custom-stat { font-size: 20px; }
}
@media (min-width: 601px) and (max-width: 900px) {
    .custom-title { font-size: 28px; }
    .custom-stats { gap: 30px; }
    .custom-stat { font-size: 24px; }
}
@media (min-width: 901px) {
    .custom-title { font-size: 36px; }
    .custom-stat { font-size: 30px; }
}