.gallery-container {
    width: 100%;
    position: relative;
    display: flex;
    margin: 0;
    flex-direction: column;
    overflow-x: hidden; 
    background-color: white; 
    color: black; 
}

.background-image {
    background-image: url('../images/newimg.png'); 
    width: 100%;
    height: 30vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.galleryoverlay {
    position: absolute;
    text-align: center;
    color: white; 
}

.overlay h1 {
    font-size: 3rem;
    font-weight: bold;
}

.overlay h2 {
    font-size: 1rem;
    font-weight: bold;
}

.image-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    padding: 100px; 
    margin-top: -90px;
    justify-items: center; 
}
.gallery-image {
    width: 100%; 
    height: auto;
    align-items: center;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

@media (max-width: 768px) {
    .overlay h1 {
        font-size: 2.5rem; 
    }

    .overlay h2 {
        font-size: 0.8rem;
    }

    .image-gallery {
        gap: 8px; 
    }
}

@media (max-width: 480px) {
    .overlay h1 {
        font-size: 2rem;
    }

    .overlay h2 {
        font-size: 0.7rem; 
    }

    .image-gallery {
        gap: 5px; 
    }
}
