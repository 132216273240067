body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.exeventsoverlay {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #dc1f81;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; 
  padding: 0 100px;
  margin-top: 0;
  box-sizing: border-box;
}

.exeventsoverlay-text {
  color: #fff;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%; 
  box-sizing: border-box;
}

.exevent-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.exbutton-group {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  margin-top: 30px;
}

.exevent-button {
  text-decoration: none;
  padding: 12px 25px;
  font-size: 1.2rem;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.excountdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 80px;
  padding: 20px 0px;
  color: white;
}

.excountdown-timer {
  display: flex;
  justify-content: center;
  gap: 20px;
  animation: fadeIn 1s ease;
}

.extime-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1200px) {
  .exeventsoverlay {
    flex-direction: column;
    height: auto;
    padding: 0 50px;
    align-items: center;
  }

  .excountdown-container {
    margin-right: 0;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .exeventsoverlay {
    padding: 0 30px;
    flex-direction: column;
  }

  .exeventsoverlay-text h1 {
    font-size: 2rem;
  }

  .exevent-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .extime-box {
    padding: 30px;
  }

  .excountdown-timer {
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .exeventsoverlay {
    padding: 0 20px;
    flex-direction: column;
  }

  .exeventsoverlay-text h1 {
    font-size: 1.8rem;
  }

  .exevent-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .extime-box {
    padding: 20px;
  }

  .excountdown-timer {
    gap: 10px;
  }
}




  .exconference-details {
    background-color: none;
    padding: 50px;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
  }
  
  .eximage-container {
    margin-top: 10px;
  }
  
  .eximage-container img {
    width: 100%;
    max-width: 1038px;
    height: 500px;
    border-radius: 10px;
  }



.extext-container {
  margin-top: 30px;
  max-width: 900px; 
  margin: 0 auto;
  padding: 30px; 
  text-align: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  border-radius: 10px; 
}

.extext-container h2 {
  color: #003366; 
  font-size: 34px; 
  font-weight: bold;
  margin-bottom: 24px; 
  letter-spacing: 1px; 
}
.extext-container p {
  font-size: 20px;
  line-height: 1.8; 
  color: #333; 
  margin-bottom: 18px;
  text-align: justify; 
}
.extext-container strong {
  font-weight: 700; 
  color: #222; 
}
.extext-container br {
  line-height: 1.8; 
}
@media (max-width: 1200px) {
  .extext-container {
    padding: 25px; 
  }
  .extext-container h2 {
    font-size: 30px; 
  }
  .extext-container p {
    font-size: 18px; 
  }
}
@media (max-width: 992px) {
  .extext-container {
    padding: 20px; 
  }

  .extext-container h2 {
    font-size: 28px;
  }

  .extext-container p {
    font-size: 17px; 
  }
}
@media (max-width: 768px) {
  .extext-container {
    padding: 15px; 
  }

  .extext-container h2 {
    font-size: 26px; 
  }

  .extext-container p {
    font-size: 16px; 
    line-height: 1.6; 
  }
}



 .exscientific-sessions {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}
.exscientific-sessions h1 {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
}
.exscientific-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.exscientific-card {
  background-color: #f9f9f9;
  flex: 1 1 calc(30% - 80px); 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.exscientific-card:hover {
  transform: translateY(-10px); 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.exscientific-card h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
  text-align: left;
}
.exscientific-card ul {
  list-style-type: none;
  padding-left: 0;
}
.exscientific-card ul li {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .exscientific-card {
    flex: 1 1 calc(33.33% - 20px); 
  }
}
@media (max-width: 768px) {
  .exscientific-card {
    flex: 1 1 calc(50% - 20px);
  }
}
@media (max-width: 480px) {
  .exscientific-card {
    flex: 1 1 100%; 
  }
} 
  .exvenue-section {
    padding: 40px;
    text-align: center;
    margin-top: 1px;
  }
  
  .exvenue-section h2 {
    font-size: 52px;
    color: #2d5e7e;
    margin-bottom: 10px;
    margin-top: 20px;
    position: relative;
  }
  
  .exvenue-section h2::after {
    content: "";
    width: 60px;
    height: 3px;
    background: #f4f4f4;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .exvenue-section p {
    font-size: 24px;
    color: #333;
  }




  
  .exscientific-agenda {
    margin-top: 1px;
    text-align: center;
    padding: 40px;
    background-color: #f4f4f4;
    color: #f1f1f1;
  }
  
  .exscientific-agenda h2 {
    font-size: 36px;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #61dafb;
  }
  
  .exday-buttons {
    margin-bottom: 30px;
  }
  .exday-buttons button {
    background-color: #61dafb;
    border: none;
    color: #fff;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
    transition: background-color 0.3s, transform 0.3s;
  }
  .exday-buttons button:hover {
    background-color: #21a1f1;
    transform: scale(1.05);
  }
  
  .exday-buttons .active {
    background-color: #333;
    color: #61dafb;
  }
  
  .exschedule-table {
    width: 90%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: #f1f1f1;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .exschedule-table th,
  .exschedule-table td {
    padding: 15px;
    text-align: left;
    font-size: 18px;
  }
  
  .exschedule-table th {
    background-color: #61dafb;
    color: white;
    font-size: 20px;
  }
  
  .exschedule-table td {
    color: #333;
  }
  
  .exschedule-table tr:nth-child(even) {
    background-color: #f7f7f7;
  }
  
  .exschedule-table tr:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
  
  .exschedule-table tr:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @media (max-width: 768px) {
    .exscientific-agenda {
      padding: 20px;
    }
  
    .exschedule-table {
      width: 100%;
    }
  
    .exday-buttons button {
      font-size: 14px;
      padding: 10px 18px;
    }
  
    .exschedule-table th,
    .exschedule-table td {
      font-size: 16px;
      padding: 10px;
    }
  }