body {
    font-family: "Poppins", sans-serif; 
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }

.newconferencesover {
    position: relative;
    width: 92%;
    height: 600px;
    background-color: #9519a8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    margin-top: 0;
    margin: 0;
  }
  
  .neweventsoverlay-text {
    color: #fff;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
  }
  
  .neweventsoverlay-text h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  
  
  .newevent-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .newevent-date,
  .newevent-location {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .new-icon {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .newbutton-group {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
  
  .newevent-button {
    text-decoration: none;
    padding: 12px 25px;
    font-size: 1.2rem;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .newevent-button:hover {
    background-color: #45a049;
    transform: translateY(-5px);
  }
  
  .newevent-button:active {
    transform: translateY(2px);
  }
  
  .newcountdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 70px;
    padding: 2 0px;
    color: white;
  }
  
  .newcountdown-timer {
    display: flex;
    justify-content: center;
    gap: 10px;
    animation: fadeIn 1s ease;
  }
  
  .new-time-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .new-time-box span:first-child {
    font-size: 2.8rem;
    font-weight: bold;
    color: #fff;
    animation: fadeNumber 1s infinite alternate;
  }
  
  .new-time-box span:last-child {
    font-size: 1.1rem;
    margin-top: 10px;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes fadeNumber {
    0% {
      color: rgba(255, 255, 255, 0.8);
    }
    100% {
      color: #fff;
    }
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .neweventsover {
      flex-direction: column;
      padding: 0 50px;
      height: auto;
    }
  
    .neweventsoverlay-text h1 {
      font-size: 2.2rem;
    }
  
    .neweventsoverlay-text p {
      font-size: 1.1rem;
    }
  
    .newevent-date,
    .newevent-location {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 1024px) {
    .neweventsover {
      padding: 0 30px;
    }
  
    .neweventsoverlay-text h1 {
      font-size: 2rem;
    }
  
    .neweventsoverlay-text p {
      font-size: 1rem;
    }
  
    .newevent-details {
      gap: 5px;
    }
  
    .newbutton-group {
      flex-direction: column;
      gap: 10px;
    }
  
    .newevent-button {
      padding: 10px 20px;
    }
  
    .newcountdown-timer {
      gap: 15px;
    }
  
    .new-time-box {
      padding: 40px;
    }
  
    .new-time-box span:first-child {
      font-size: 2.4rem;
    }
  
    .new-time-box span:last-child {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .neweventsover {
      padding: 0 20px;
    }
  
    .neweventsoverlay-text h1 {
      font-size: 1.8rem;
    }
  
    .neweventsoverlay-text p {
      font-size: 0.9rem;
    }
  
    .newevent-details {
      gap: 5px;
    }
  
    .newcountdown-timer {
      gap: 10px;
      margin-left: 70px;
      flex-direction: row;
    }
  
    .new-time-box {
      padding: 30px;
    }
  
    .new-time-box span:first-child {
      font-size: 2rem;
    }
  
    .new-time-box span:last-child {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .neweventsover {
      padding: 0 10px;
    }
  
    .neweventsoverlay-text h1 {
      font-size: 1.5rem;
    }
  
    .neweventsoverlay-text p {
      font-size: 0.8rem;
    }
  
    .newevent-details {
      gap: 5px;
    }
  
    .newevent-button {
      padding: 8px 15px;
      font-size: 1rem;
    }
  
    .newcountdown-container {
      margin-right: 0;
    }
  
    .new-time-box {
      padding: 10px;
    }
  
    .new-time-box span:first-child {
      font-size: 1.8rem;
    }
  
    .new-time-box span:last-child {
      font-size: 0.8rem;
    }
  }
  
    .newconference-details {
      background-color: none;
      padding: 50px;
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333;
    }
    
    .newimage-container {
      margin-top: 10px;
    }
    
    .newimage-container img {
      width: 100%;
      max-width: 1038px;
      height: 500px;
      border-radius: 10px;
    }
  
  .confnewtext-container {
    margin-top: 30px;
    max-width: 900px;
    margin: 0 auto;
    padding: 30px; 
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif; 
    border-radius: 10px; 
  }
  
  .confnewtext-container h2 {
    color: #003366;
    font-size: 34px; 
    font-weight: bold;
    margin-bottom: 24px;
    letter-spacing: 1px; 
  }
  
  .confnewtext-container p {
    font-size: 20px; 
    line-height: 1.8; 
    color: #333; 
    margin-bottom: 18px; 
    text-align: justify; 
  }
  
  .confnewtext-container strong {
    font-weight: 700; 
    color: #222; 
  }
  
  .confnewtext-container br {
    line-height: 1.8; 
  }
  
  
  @media (max-width: 1200px) {
    .confnewtext-container {
      padding: 25px; 
    }
    .confnewtext-container h2 {
      font-size: 30px; 
    }
    .confnewtext-container p {
      font-size: 18px; 
    }
  }
  @media (max-width: 992px) {
    .confnewtext-container {
      padding: 20px; 
    }
    .confnewtext-container h2 {
      font-size: 28px; 
    }
    .confnewtext-container p {
      font-size: 17px; 
    }
  }
  @media (max-width: 768px) {
    .confnewtext-container {
      padding: 15px; 
    }
    .confnewtext-container h2 {
      font-size: 26px; 
    }
    .confnewtext-container p {
      font-size: 16px; 
      line-height: 1.6; 
    }
  }

  .conference-sessions {
      max-width: 1200px;
      margin: 0 auto;
      padding: 40px 20px;
      font-family: 'Helvetica Neue', Arial, sans-serif;
    }
    
    .conference-sessions h1 {
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #333;
      margin-bottom: 40px;
    }
    
    .newconference-card-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
    }
    
    .newconference-card {
      background-color: #f9f9f9;
      flex: 1 1 calc(30% - 80px); 
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .newconference-card:hover {
      transform: translateY(-10px); 
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    
    .newconference-card h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #333;
      text-align: left;
    }
    
    .newconference-card ul {
      list-style-type: none;
      padding-left: 0;
    }
    
    .newconference-card ul li {
      font-size: 16px;
      color: #555;
      margin-bottom: 10px;
    }
    
    @media (max-width: 1024px) {
      .newconference-card {
        flex: 1 1 calc(33.33% - 20px); 
      }
    }
    
    @media (max-width: 768px) {
      .newconference-card {
        flex: 1 1 calc(50% - 20px); 
      }
    }
    
    @media (max-width: 480px) {
      .newconference-card {
        flex: 1 1 100%; 
      }
    } 
  
    .new-venue-section {
      padding: 40px;
      text-align: center;
      margin-top: 1px;
    }
    
    .new-venue-section h2 {
      font-size: 52px;
      color: #2d5e7e;
      margin-bottom: 10px;
      margin-top: 20px;
      position: relative;
    }
    
    .new-venue-section h2::after {
      content: "";
      width: 60px;
      height: 3px;
      background: #f4f4f4;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .new-venue-section p {
      font-size: 24px;
      color: #333;
    }

    .newconference-agenda {
      margin-top: 1px;
      text-align: center;
      padding: 40px;
      background-color: #f4f4f4;
      color: #f1f1f1;
    }
    
    .newconference-agenda h2 {
      font-size: 36px;
      margin-bottom: 30px;
      text-transform: uppercase;
      color: #61dafb;
    }
    
    .newday-buttons {
      margin-bottom: 30px;
    }
    
    .newday-buttons button {
      background-color: #61dafb;
      border: none;
      color: #fff;
      padding: 12px 24px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      margin: 5px;
      transition: background-color 0.3s, transform 0.3s;
    }
    
    .newday-buttons button:hover {
      background-color: #21a1f1;
      transform: scale(1.05);
    }
    
    .newday-buttons .active {
      background-color: #333;
      color: #61dafb;
    }
    
    .newconference-table {
      width: 90%;
      margin: 0 auto;
      border-collapse: collapse;
      background-color: #f1f1f1;
      border-radius: 8px;
      overflow: hidden;
    }
    
    .newconference-table th,
    .newconference-table td {
      padding: 15px;
      text-align: left;
      font-size: 18px;
    }
    
    .newconference-table th {
      background-color: #61dafb;
      color: white;
      font-size: 20px;
    }
    
    .newconference-table td {
      color: #333;
    }
    
    .newconference-table tr:nth-child(even) {
      background-color: #f7f7f7;
    }
    
    .newconference-table tr:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
    
    .newconference-table tr:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    
    @media (max-width: 768px) {
      .newconference-agenda {
        padding: 20px;
      }
    
      .newconference-table {
        width: 100%;
      }
    
      .newday-buttons button {
        font-size: 14px;
        padding: 10px 18px;
      }
    
      .newconference-table th,
      .newconference-table td {
        font-size: 16px;
        padding: 10px;
      }
    }
    
  
    .payment-carai {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 100vh;
      padding: 50px 20px;
      background: linear-gradient(to bottom, #044a6b, #0a3c5d);
  }
  
  .intro-payment h1 {
      font-size: 2.8em;
      font-weight: bold;
      margin-bottom: 20px;
      color: #FFFFFF;
      animation: fadeInDown 1s ease-in-out;
  }
  
  .intro-payment p {
      font-size: 1.2em;
      margin: 10px 0;
      color: #E0E0E0;
      animation: fadeInUp 1s ease-in-out;
  }
  
  .payment-card-carai {
      display: grid;
      grid-template-columns: repeat(3, 1fr); 
      gap: 70px; 
      max-width: 1200px; 
      margin: 0 auto;
      padding: 0 20px; 
      justify-content: center; 
      animation: fadeIn 1.5s ease-in-out;
  }
  
  .payment-caraicard {
      background-color: #F7F9FC;
      color: #0D3B66;
      border-radius: 10px;
      padding: 25px;
      width: 100%;
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
      text-align: center;
      transform: scale(1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
  }
  
  .payment-caraicard:hover {
      transform: scale(1.08);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  .payment-caraicard h3 {
      font-size: 1.8em;
      margin-bottom: 15px;
      color: #0056b3;
      text-transform: uppercase;
      letter-spacing: 1.5px;
  }
  
  .payment-caraicard ul {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;
  }
  
  .payment-caraicard ul li {
      font-size: 1em;
      margin: 8px 0;
      color: #3C4858;
  }
  
  .payment-caraicard p {
      font-size: 1.6em;
      color: #FF5A5F;
      font-weight: bold;
      margin-bottom: 20px;
  }
  
  .payment-caraicard button {
      background-color: #007BFF;
      color: #FFFFFF;
      border: none;
      padding: 12px 25px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease;
  }
  
  .payment-caraicard button:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
  }
  
  @keyframes fadeIn {
      from {
          opacity: 0;
          transform: translateY(20px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @keyframes fadeInDown {
      from {
          opacity: 0;
          transform: translateY(-30px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @keyframes fadeInUp {
      from {
          opacity: 0;
          transform: translateY(30px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  @media (max-width: 1024px) {
      .payment-card-carai {
          grid-template-columns: repeat(2, 1fr); 
          gap: 60px; 
          padding: 0 30px; 
      }
  }
  
  @media (max-width: 768px) {
      .payment-card-carai {
          grid-template-columns: 1fr; 
          gap: 40px; 
          padding: 0 15px; 
      }
  }
  
  @media (max-width: 480px) {
      .payment-card-carai {
          grid-template-columns: 1fr; 
          gap: 30px; 
          padding: 0 10px; 
      }
  }
  