body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}
.spoverlay-cont {
  position: relative;
  width: 90%;
  height: 600px;
  margin-top: 0;
  margin: 0;
  background-color: #2d5e7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  }
  .spoverlay-text {
    color: #fff;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
  }
  .spoverlay-text h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .spoverlay-text p {
    font-size: 1.3rem;
    margin-bottom: 30px;
  }
  .spevent-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  .spevent-date,
  .spevent-location {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .spicon {
    font-size: 1.5rem;
    color: #fff;
  }
  .spbutton-group {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
  .spevent-button {
    text-decoration: none;
    padding: 12px 25px;
    font-size: 1.2rem;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .spevent-button:hover {
    background-color: #45a049;
    transform: translateY(-5px);
  }
  .spevent-button:active {
    transform: translateY(2px);
  }

  .spcountdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 100px;
    padding: 2 0px;
    color: white;
  }
  .spcountdown-timer {
    display: flex;
    justify-content: center;
    gap: 20px;
    animation: fadeIn 1s ease;
  }
  .sptime-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation: bounce 1.5s infinite ease-in-out;
  }
  .sptime-box span:first-child {
    font-size: 2.8rem;
    font-weight: bold;
    color: #fff;
    animation: fadeNumber 1s infinite alternate;
  }
  .sptime-box span:last-child {
    font-size: 1.1rem;
    margin-top: 10px;
  }
@media (max-width: 1024px) {
  .spoverlay-cont {
    flex-direction: column;
    padding: 0 50px;
    height: auto;
  }
  .spoverlay-text {
    text-align: center;
    padding: 20px 0;
  }
  .spcountdown-container {
    margin-right: 0;
    margin-top: 20px;
  }
  .sptime-box {
    padding: 30px;
  }
}
@media (max-width: 768px) {
  .spoverlay-cont {
    padding: 0 20px;
    justify-content: center;
    align-items: flex-start;
  }
  .spoverlay-text h1 {
    font-size: 2rem;
  }
  .spoverlay-text p {
    font-size: 1.1rem;
  }
  .spbutton-group {
    flex-direction: column;
    gap: 15px;
  }
  .spevent-button {
    width: 100%;
    text-align: center;
  }
  .spcountdown-container {
    padding: 0;
  }
  .spcountdown-timer {
    flex-direction: column;
    gap: 15px;
  }
  .sptime-box {
    padding: 20px;
  }
  .sptime-box span:first-child {
    font-size: 2.3rem;
  }
}
@media (max-width: 480px) {
  .spoverlay-cont {
    padding: 0 10px;
  }
  .spoverlay-text h1 {
    font-size: 1.8rem;
  }
  .spoverlay-text p {
    font-size: 1rem;
  }
  .spevent-details {
    margin-top: 15px;
  }
  .spevent-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
  .sptime-box span:first-child {
    font-size: 2rem;
  }
  .sptime-box span:last-child {
    font-size: 1rem;
  }
}

  .spconference-details {
    background-color: none;
    padding: 50px;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
  }
  .spimage-container {
    margin-top: 10px;
  }
  .spimage-container img {
    width: 100%;
    max-width: 1038px;
    height: 500px;
    border-radius: 10px;
  }

  .sptext-container {
    margin-top: 30px;
    max-width: 900px; 
    margin: 0 auto;
    padding: 30px; 
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    border-radius: 10px; 
  }
  
  .sptext-container h2 {
    color: #003366; 
    font-size: 34px; 
    font-weight: bold;
    margin-bottom: 24px; 
    letter-spacing: 1px; 
  }
  .sptext-container p {
    font-size: 20px;
    line-height: 1.8; 
    color: #333; 
    margin-bottom: 18px;
    text-align: justify; 
  }
  .sptext-container strong {
    font-weight: 700; 
    color: #222; 
  }
  .sptext-container br {
    line-height: 1.8; 
  }
  @media (max-width: 1200px) {
    .sptext-container {
      padding: 25px; 
    }
    .sptext-container h2 {
      font-size: 30px; 
    }
    .sptext-container p {
      font-size: 18px; 
    }
  }
  @media (max-width: 992px) {
    .sptext-container {
      padding: 20px; 
    }
  
    .sptext-container h2 {
      font-size: 28px;
    }
  
    .sptext-container p {
      font-size: 17px; 
    }
  }
  @media (max-width: 768px) {
    .sptext-container {
      padding: 15px; 
    }
  
    .sptext-container h2 {
      font-size: 26px; 
    }
  
    .sptext-container p {
      font-size: 16px; 
      line-height: 1.6; 
    }
  }
  

  .spscientific-sessions {
    padding: 50px 100px;
    background-color: #f4f4f4;
    text-align: center;
    margin-top: 1px;
  }
  
  .spscientific-sessions h1 {
    font-size: 3rem;
    color: #2d5e7e;
    margin-bottom: 20px;
    position: relative;
  }
  .spscientific-sessions h1::after {
    content: "";
    width: 60px;
    height: 2px;
    background: #f4f4f4;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .spscientific-sessions ul {
    list-style: none;
    padding: 0;
    max-width: 900px;
    margin: 0 auto;
  }
  .spscientific-sessions ul li {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  .spscientific-sessions ul li:hover {
    transform: scale(1.02);
    background-color: #eef4fa;
  }



  .spvenue-section {
    padding: 40px;
    text-align: center;
    margin-top: 1px;
  }
  .spvenue-section h2 {
    font-size: 52px;
    color: #2d5e7e;
    margin-bottom: 10px;
    margin-top: 20px;
    position: relative;
  }
  .spvenue-section h2::after {
    content: "";
    width: 60px;
    height: 3px;
    background: #f4f4f4;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .spvenue-section p {
    font-size: 24px;
    color: #333;
  }


  .spscientific-agenda {
    margin-top: 1px;
    text-align: center;
    padding: 40px;
    background-color: #f4f4f4;
    color: #f1f1f1;
  }
  .spscientific-agenda h2 {
    font-size: 36px;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #61dafb;
  }
  .spday-buttons {
    margin-bottom: 30px;
  }
  .spday-buttons button {
    background-color: #61dafb;
    border: none;
    color: #fff;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
    transition: background-color 0.3s, transform 0.3s;
  }
  .spday-buttons button:hover {
    background-color: #21a1f1;
    transform: scale(1.05);
  } 
  .spday-buttons .active {
    background-color: #333;
    color: #61dafb;
  }
  .spschedule-table {
    width: 90%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: #f1f1f1;
    border-radius: 8px;
    overflow: hidden;
  }
  .spschedule-table th,
  .spschedule-table td {
    padding: 15px;
    text-align: left;
    font-size: 18px;
  }
  .spschedule-table th {
    background-color: #61dafb;
    color: white;
    font-size: 20px;
  }
  .spschedule-table td {
    color: #333;
  }
  .spschedule-table tr:nth-child(even) {
    background-color: #f7f7f7;
  }
  .spschedule-table tr:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
  .spschedule-table tr:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @media (max-width: 768px) {
    .spscientific-agenda {
      padding: 20px;
    }
  
    .spschedule-table {
      width: 100%;
    }
  
    .spday-buttons button {
      font-size: 14px;
      padding: 10px 18px;
    }
  
    .spschedule-table th,
    .spschedule-table td {
      font-size: 16px;
      padding: 10px;
    }
  }
  