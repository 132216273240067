
.landing-page {
    min-height: 100vh;
    background: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem;
    height: 80px;
  }
  .logo-container {
    height: 55px;
  }
  .logo {
    height: 100%;
    object-fit: contain;
  }
  .nav-right {
    display: flex;
    align-items: center;
    gap: 15rem;
  }
  .website-text {
    color: #333;
    font-size: 1.2rem;
  }
  .secure-btn {
    background-color: #7C3AED;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .secure-btn:hover {
    background-color: #6D28D9;
  }
  .secure-btn.large {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }
  .main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    max-width: 1500px;
    margin: 0 auto;
    gap: 4rem;
  }
  .content-left {
    flex: 1;
    max-width: 650px;
    margin-left: 20px;
  }
  .gradient-title {
    font-size: 3.8rem;
    font-weight: bold;
    width: 110%;
    line-height: 1.2;
    margin-bottom: 19rem;
    background: linear-gradient(
      135deg,
      #8B5CF6 0%,
      #D946EF 50%,
      #EC4899 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .subtitle {
    font-size: 1.25rem;
    color: #4B5563;
    margin-top: -17rem;
    line-height: 1.6;
  }
  .content-right {
    flex: 1;
    max-width: 600px;
  }
  .hero-image {
    width: 75%;
    height: auto;
    margin-left: 90px;
    border-radius: 1rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
                0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  /* Responsive Design */
  @media (max-width: 1024px) {
    .main-content {
      flex-direction: column;
      padding: 2rem;
    }
    .gradient-title {
      font-size: 2.5rem;
    }
    .content-left,
    .content-right {
      max-width: 100%;
    }
    .nav-container {
      padding: 1rem 2rem;
    }
  }
  @media (max-width: 640px) {
    .nav-container {
      flex-direction: column;
      height: auto;
      gap: 1rem;
      padding: 1rem;
    }
    .nav-right {
      flex-direction: column;
      gap: 1rem;
    }
    .gradient-title {
      font-size: 2rem;
    }
  }
.package-section {
    background-color: #7C3AED;
    padding: 4rem 2rem;
    color: white;
  }
  .section-title {
    margin-left: 30px;
    font-size: 3.2rem;
    font-weight: 600;
    margin-bottom: 3rem;
  }
  .package-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  .package-aicard {
    padding: 2rem;
    border-radius: 1rem;
  }
  .package-aicard h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .package-aicard p {
    font-size: 1rem;
    line-height: 1.6;
  }
  .price-list p {
    margin-bottom: 0.5rem;
  }
  
  /* Features Section Styles */
  .features-container {
    background-color: white;
    border-radius: 1rem;
    padding: 3rem;
    max-width: 1200px;
    margin: 4rem auto;
  }
  
  .features-title {
    color: #7C3AED;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .feature-card {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.5rem;
    background-color: #F9FAFB;
    border-radius: 0.5rem;
  }
  
  .feature-number {
    background-color: #EDE9FE;
    color: #7C3AED;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-weight: 600;
  }
  
  .feature-content h4 {
    color: #1F2937;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .feature-content p {
    color: #4B5563;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .package-grid {
      grid-template-columns: 1fr;
      max-width: 600px;
    }
  
    .features-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 640px) {
    .package-section {
      padding: 2rem 1rem;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    .features-container {
      padding: 1.5rem;
      margin: 2rem auto;
    }
  
    .feature-card {
      padding: 1rem;
    }
  }


  /* Ethical AI Section */
.ethical-ai-section {
    padding: 4rem 2rem;
    background-color: white;
  }
  
  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: -4rem;
  }
  
  .gradient-heading {
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(
      135deg,
      #8B5CF6 0%,
      #EC4899 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 3rem;
  }
  
  .ethics-list {
    list-style: none;
    padding: 0;
  }
  
  .ethics-list li {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #4B5563;
    margin-bottom: 1rem;
  }
  
  .bullet {
    color: #8B5CF6;
    margin-right: 1rem;
    font-weight: bold;
  }
  
/* AI-Powered Development Section */
.development-section {
    padding: 4rem 2rem;
    background-color: #F9FAFB;
  }
  
  .development-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
  }
  
  .image-container {
    width: 100%;
  }
  
  .developer-image {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    margin-top: -40px;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .timeline-item {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }
  
  .timeline-number {
    background-color: #EDE9FE;
    color: #7C3AED;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-weight: 600;
    flex-shrink: 0;
  }
  
  .timeline-content {
    flex-grow: 1;
  }
  
  .timeline-content h4 {
    color: #1F2937;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .timeline-content p {
    color: #4B5563;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* AI Features Section */
  .ai_layout_wrapper {
    max-width: 100%;
    margin: 0 auto;
  }
  
  .ai_split_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .ai_content_section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .ai_hero_title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 3rem;
    background: linear-gradient(to right, #7c3aed, #ec4899);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    line-height: 1.2;
  }
  
  .ai_features_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .ai_feature_box {
    background: rgb(226, 193, 236);
    backdrop-filter: blur(8px);
    border-radius: 0.75rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .ai_feature_box:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .ai_feature_heading {
    font-size: 1.25rem;
    font-weight: 600;
    color: #5b21b6;
    margin-bottom: 1rem;
  }
  
  .ai_feature_text {
    color: #090808;
    line-height: 1.5;
  }
  
  .ai_media_wrapper {
    position: relative;
    height: 600px;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
  
  .ai_hero_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .ai_image_overlay {
    position: absolute;
    inset: 0;
    /* background: rgba(30, 58, 138, 0.2); */
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .development-grid {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  
    .gradient-heading {
      font-size: 2.5rem;
    }
  
    .ai_split_container {
      grid-template-columns: 1fr;
    }
  
    .ai_media_wrapper {
      height: 400px;
    }
  }
  
  @media (max-width: 768px) {
    .ai_features_grid {
      grid-template-columns: 1fr;
    }
  
    .ai_hero_title {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 640px) {
    .development-section {
      padding: 2rem 1rem;
    }
  
    .timeline-item {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .timeline-number {
      margin-bottom: 0.5rem;
    }
  
    .ai_hero_title {
      font-size: 2rem;
    }
  }






 .impact-section {
    background: #000;
    min-height: 100vh;
    padding: 60px 20px;
    position: relative;
    color: white;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
  }
  
  .impact-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(0,100,255,0.1), transparent);
    pointer-events: none;
  }
  
  .impact-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
  h1 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 60px;
    font-family: serif;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
  }
  
  .steps-container::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 2px;
    background: rgba(255,255,255,0.1);
  }
  
  .step {
    flex: 1;
    position: relative;
    padding-top: 80px;
  }
  
  .step-number {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: #e6e6fa;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
    width: 100%;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    line-height: 1.6;
    /* color: rgba(255,255,255,0.9); we can change the color here */
  }
  @media (max-width: 768px) {
    .steps-container {
      flex-direction: column;
    }
    .step {
      margin-bottom: 40px;
    }
    .step-number {
      width: 80%;
      margin: 0 auto;
    }
  } 







  
  
.innovation-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}
.innovation-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}
.innovation-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.innovation-title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  color: #333;
}
.innovation-title span {
  background: linear-gradient(to right, #7c3aed, #ec4899);
  -webkit-background-clip: text;
  color: transparent;
}


.innovation-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}
.innovation-feature {
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #7c3aed;
}
.feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}
.feature-description {
  font-size: 1rem;
  color: #555;
}
.innovation-image-container {
  position: relative;
  height: auto;
  max-height: 600px; 
  border-radius: 0.75rem;
  overflow: hidden;
  margin-left: 70px;
  margin-bottom: -20px; 
}
.innovation-image {
  width: 80%; 
  height: auto; 
  object-fit: cover;
  border-radius: 0.75rem;
  margin: 0 auto; 
  display: block; 
}
@media (max-width: 1024px) {
  .innovation-container {
    grid-template-columns: 1fr; 
  }
  .innovation-image-container {
    max-height: 400px;
    margin: 0 auto; 
  }
  .innovation-title {
    font-size: 2.5rem;
    text-align: center;
  }
  .innovation-content {
    align-items: center; 
  }
  .innovation-features {
    grid-template-columns: 1fr 1fr; 
    gap: 1rem;
  }
}
@media (max-width: 768px) {
  .innovation-container {
    grid-template-columns: 1fr;
  }
  .innovation-title {
    font-size: 2rem;
    text-align: center;
  }
  .innovation-content {
    align-items: center;
    text-align: center;
  }
  .innovation-features {
    grid-template-columns: 1fr; 
    gap: 1rem;
  }
  .innovation-image-container {
    max-height: 300px;
    margin-bottom: 10px;
  }
  .innovation-image {
    width: 90%; 
  }
}
@media (max-width: 480px) {
  .innovation-title {
    font-size: 1.8rem;
  }
  .feature-title {
    font-size: 1.1rem;
  }
  .feature-description {
    font-size: 0.9rem;
  }
  .innovation-image-container {
    max-height: 250px;
  }
}